.about_section_1 {
    padding: 70px 135px;
}

.about_section_1 p {
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 20px;
}


.about_section_2 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.about_section_2 .img_container {
    width: 570px;
    background: #EC1F25;
    height: 580px;
}

.about_section_2 .img_container img{
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 30px;
    right: 35px;
}





.about_section_3 {
    padding: 89px 130px;
}

.about_section_3 .who_we_are h1 {
    font-size: 64px;
    font-weight: 700;
    line-height: 55px;
    color: #EC1F25;
}

.about_section_3 .who_we_are h1 > span {
    color: #323D50;
}

.about_section_3 .who_we_are .line {
    background-color: #323D50;
    height: 4px;
    width: 150px;
    margin: 22px 0 50px;
}

.about_section_3 ul li{
    color: #323D50;
    font-family: Segoe UI;
    font-size: 25px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 25px;
}




@media screen and (max-width: 430px) {
    .about_section_1 {
        padding: 20px;
    }
    
    .about_section_1 p {
        font-family: Segoe UI;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 15px;
    }
    
    .about_section_2 {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    
    .about_section_2 .img_container {
        width: 90%;
        background: #EC1F25;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        /* overflow: hidden; */
    }
    
    .about_section_2 .img_container img {
        width: 100%;
        /* height: auto; */
        object-fit: cover;
        bottom: 20px;
        right: 25px;
    }
    
    .about_section_3 {
        padding: 20px;
    }
    
    .about_section_3 .who_we_are h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        color: #EC1F25;
    }
    
    .about_section_3 .who_we_are h1 > span {
        color: #323D50;
    }
    
    .about_section_3 .who_we_are .line {
        background-color: #323D50;
        height: 2px;
        width: 100px;
        margin: 15px 0 30px;
    }
    
    .about_section_3 ul {
        padding: 0;
        list-style: none;
    }
    
    .about_section_3 ul li {
        color: #323D50;
        font-family: Segoe UI;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 15px;
    }
}