.newsletter {
    background: #F3F3F3;
    padding: 140px 400px 70px;
    margin-bottom: 45px;
    width: 100%;
}

.newsletter .newsletter-content h3{
    font-family: Segoe UI;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 55px;
    color: #292D32;
}

.newsletter .newsletter-content p{
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 4px;
    line-height: 55px;
    color: #323D4F9C;
}

.newsletter .form_field input {
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 600;
    line-height: 55px;
    border: 1px solid #292D32;
    background: none;
    border-radius: 50px;
    margin-bottom: 60px;
    width: 100%;
    padding: 2px 20px;
}
.newsletter .form_field input.calc {
    border-radius: 0;
}

.newsletter .form_field label {
    display: block;
    width: 100%;
    text-align: left;
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.newsletter .newsletter_form button{
    border-radius: 50px;
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 700;
    background: #323D4F;
    border: none;
    border: 1px solid #323D4F9C;
    padding: 2px 40px;
    color: #fff;
    line-height: 55px;
}