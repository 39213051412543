.consulting_section_1 .image_section {
    margin: 0 auto 50px;
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 430px) {
    .image_section img {
        width: 100%;
    }
}

