.contact_us_section_1 {
  padding: 75px 130px 90px;
}

.contact_us_section_1 h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 55px;
  text-align: center;
  color: #323d50;
}

.contact_us_section_1 .line {
  margin: 12px auto 60px;
  width: 130px;
  height: 4px;
  background: #323d50;
}

.contact_us_section_1 .contact_info_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 85px;
}

.contact_info_container .text_info {
  display: grid;
  gap: 20px;
}

.contact_info_container .info_item {
  /* background-color: #324950; */
  padding: 30px 60px;
  /* color: #fff; */
  box-shadow: 0px 0px 10px 0px #00000040;
  border-radius: 10px;
  width: 100%;
}

.contact_info_container .info_item h3 {
  font-family: Segoe UI;
  font-size: 25px;
  font-weight: 700;
  line-height: 18px;
  color: #323d50;
  margin-bottom: 5px;
}

.contact_info_container .info_item p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  color: #323d5099;
  margin-top: 5px;
}

@media screen and (max-width: 430px) {
  .contact_us_section_1 {
    padding: 60px 20px 100px;
  }

  .contact_us_section_1 h1 {
    font-size: 36px;
    line-height: 40px;
  }

  .contact_us_section_1 .line {
    margin: 8px auto 30px;
    width: 80px;
    height: 3px;
  }

  .contact_us_section_1 .contact_info_container {
    display: block;
  }

  .contact_info_container .text_info {
    display: block;
    gap: 15px;
  }

  .contact_info_container .info_item {
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 5px 0px #00000020;
  }

  .contact_info_container .info_item h3 {
    font-size: 18px;
    line-height: 22px;
  }

  .contact_info_container .info_item p {
    font-size: 14px;
    line-height: 18px;
    margin-top: 3px;
  }

  .contact_info_container .map_info {
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }

  .contact_info_container .map_info iframe {
    width: 100%;
    height: 100%;
  }
}
