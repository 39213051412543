.team_card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 430px;
  /* background: #ddd; */
  padding-bottom: 20px;
}

.team_card_container .card_image {
  height: 470px;
  min-width: 430px;
  background: #323d50;
  position: relative;
}

.team_card_container .card_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.team_card_container .card_text {
  text-align: center;
  margin-top: 20px;
}

.team_card_container .card_text .name,
.team_card_container .card_text .position {
  font-family: Segoe UI;
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
  color: #323d4f;
}

/* Modal Styling */
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 4;
  align-items: center;
  overflow-y: auto; /* Enables scrolling if content overflows */
  padding: 20px; /* Add padding to give space around the modal */
}

.modal_content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 60%;
  max-width: 600px;
  max-height: 80vh; /* Limit the height to ensure it doesn't exceed the viewport */
  overflow-y: auto; /* Scroll content within the modal if it overflows */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.modal_close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.modal_content h2,
.modal_content h3,
.modal_content p {
  margin-top: 0;
  margin-bottom: 10px;
}

.modal_content p {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

@media screen and (max-width: 430px) {
  /* Mobile Styles for screens with max-width of 430px */

  .team_card_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: unset;
    width: 100%;
    padding-bottom: 20px;
    /* margin: 10px; */
  }

  .team_card_container .card_image {
    min-width: unset;
    width: 100%;
    height: 400px; /* Adjusted height for mobile screens */
    background: #323d50;
    position: relative;
  }

  .team_card_container .card_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .team_card_container .card_text {
    text-align: center;
    margin-top: 10px; /* Reduced margin for mobile screens */
  }

  .team_card_container .card_text .name,
  .team_card_container .card_text .position {
    font-family: Segoe UI;
    font-size: 18px; /* Adjusted font size for mobile screens */
    font-weight: 600;
    line-height: 24px;
    color: #323d4f;
  }

  .modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    overflow-y: auto;
    padding: 10px; /* Reduced padding for mobile screens */
  }

  .modal_content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%; /* Adjusted width for mobile screens */
    max-width: 400px; /* Adjusted max-width for mobile screens */
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    position: relative;
  }

  .modal_close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px; /* Adjusted font size for mobile screens */
    cursor: pointer;
    color: #333;
  }

  .modal_content h2,
  .modal_content h3,
  .modal_content p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .modal_content p {
    font-size: 14px; /* Adjusted font size for mobile screens */
    line-height: 1.5;
    color: #333;
  }
}
