/* Job Section 1 */
.job_section_1 {
  padding: 110px 85px;
  display: flex;
  align-items: center;
  gap: 104px;
}

.job_section_1 .item_text h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 55px;
  color: #323d50;
}

.job_section_1 .item_text .line {
  height: 4px;
  width: 120px;
  background: #323d50;
  margin: 15px 0;
}

.job_section_1 .item_text p {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 600;
  color: #323d50;
  line-height: 24px;
}

.job_section_2 {
  background: #f3f3f3;
  padding: 68px 400px;
  margin-bottom: 40px;
}

.job_section_2 h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 55px;
  color: #292d32;
  text-align: center;
  margin-bottom: 90px;
}

.job_section_2 .form_field input,
.job_section_2 .form_field select {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 600;
  line-height: 55px;
  border: 1px solid #292d32;
  background: none;
  border-radius: 50px;
  margin-bottom: 60px;
  width: 100%;
  padding: 2px 20px;
}

.job_section_2 .form_field select {
  padding: 20px;
}

.job_section_2 .form_field input.calc {
  border-radius: 0;
}

.job_section_2 .form_field label {
  display: block;
  width: 100%;
  text-align: left;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 12px;
}

.job_section_2 .application_form form button {
  border-radius: 50px;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 700;
  background: #323d4f;
  border: none;
  border: 1px solid #323d4f9c;
  padding: 2px 40px;
  color: #fff;
  line-height: 55px;
}

/* Job Advert Section */
.job_advert {
  padding: 40px 85px;
  background: #f9f9f9;
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

.job_advert h1 {
  font-size: 48px;
  font-weight: 700;
  color: #323d50;
  margin-bottom: 20px;
}

.advert_details h2 {
  font-size: 36px;
  font-weight: 600;
  color: #007bff;
}

.advert_details h3 {
  font-size: 24px;
  font-weight: 600;
  color: #555;
  margin-top: 20px;
}

.advert_details ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.advert_details li {
  font-size: 16px;
  line-height: 24px;
  color: #323d50;
  margin-bottom: 5px;
}

.advert_details button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.advert_details button:hover {
  background-color: #0056b3;
}

/* Popup Styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 60px 0 60px;
  overflow: auto;
}

.popup_content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  position: relative;
  margin: 60px 0 0;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #999;
}

.popup_content h1 {
  margin: 0 0 15px 0;
}




/* Popup Styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup_content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.popup_content h1 {
  margin: 0 0 20px 0;
  font-size: 24px;
  font-weight: 700;
  color: #323d50;
  text-align: center;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 20px;
  color: #999;
}

.close:hover {
  color: #323d50;
}

.popup_content .form_field {
  margin-bottom: 20px;
}

.popup_content .form_field label {
  display: block;
  text-align: left;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 600;
  color: #323d50;
}

.popup_content .form_field input,
.popup_content .form_field select {
  font-family: Segoe UI;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #292d32;
  border-radius: 50px;
  padding: 12px 20px;
  width: 100%;
}

.popup_content .form_field input[type="file"] {
  border: none;
}

.popup_content button[type="submit"] {
  border-radius: 50px;
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 700;
  background: #323d4f;
  border: none;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.popup_content button[type="submit"]:hover {
  background: #292d32;
}


.loading_spinner {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}



/* Media Queries */
@media screen and (max-width: 430px) {
  .job_section_1 {
    padding: 100px 20px;
    display: block;
    text-align: center;
  }

  .job_section_1 .item_text h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .job_section_1 .item_text .line {
    width: 60px;
    margin: 10px auto;
  }

  .job_section_1 .item_text p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .job_advert {
    padding: 40px 10px;
    margin: 20px 10px;
  }

  .job_section_1 .item_image {
    margin-top: 20px;
  }

  .job_section_1 .item_image img {
    width: 100%;
  }

  .job_section_2 {
    padding: 20px;
  }

  .job_section_2 h1 {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 30px;
  }

  .job_section_2 .form_field {
    margin-bottom: 20px;
  }

  .job_section_2 .form_field input,
  .job_section_2 .form_field select {
    font-size: 14px;
    padding: 10px 15px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .job_section_2 .form_field label {
    font-size: 14px;
    padding: 8px;
  }

  .job_section_2 .application_form form button {
    padding: 10px 20px;
    line-height: 24px;
  }
}
