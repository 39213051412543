.core_value_section_1 {
  padding: 95px 200px 0;
  text-align: center;
}

.core_value_section_1 div h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 55px;
  color: #323d50;
  margin-bottom: 24px;
}

.core_value_section_1 div p {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: #323d50;
  margin-bottom: 110px;
}

.core_value_section_2 {
  display: flex;
  justify-content: center;
}

.core_value_section_2 .line {
  width: 4px;
  height: 130px;
  background: #323d50;
  margin-bottom: 55px;
  margin: 55px auto;
}

.core_value_section_2 h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 55px;
  color: #323d50;
  margin-bottom: 24px;
  margin-bottom: 100px;
}

.core_value_section_2 h1 > span {
  color: #ec1f25;
}

.core_value_section_3 {
  text-align: center;
  padding: 0 260px 80px;
}

.core_value_section_3 div h3 {
  font-family: Segoe UI;
  font-size: 25px;
  font-weight: 700;
  line-height: 18px;
  color: #323d50;
  margin-bottom: 15px;
}

.core_value_section_3 div p {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: #323d50;
  margin-bottom: 80px;
}

@media screen and (max-width: 430px) {
  /* Mobile Styles for screens with max-width of 430px */

  .core_value_section_1 {
    padding: 60px 20px 20px;
    text-align: center;
  }

  .core_value_section_1 div h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: #323d50;
    margin-bottom: 15px;
  }

  .core_value_section_1 div p {
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #323d50;
    margin-bottom: 40px;
  }

  .core_value_section_2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }


  .core_value_section_2 .line {
    width: 2px;
    height: 60px;
    background: #323d50;
    /* margin-bottom: 55px; */
    margin: 20px auto;
  }

  .core_value_section_2 h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: #323d50;
    margin-bottom: 15px;
  }

  .core_value_section_2 h1 > span {
    color: #ec1f25;
  }

  .core_value_section_3 {
    text-align: center;
    padding: 20px;
  }

  .core_value_section_3 div h3 {
    font-family: Segoe UI;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #323d50;
    margin-bottom: 10px;
  }

  .core_value_section_3 div p {
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #323d50;
    margin-bottom: 40px;
  }
}
