.system_monitoring {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.system_monitoring img {
    margin: 0 auto 60px;
}

.system_monitoring p {
    text-align: center;
}


.business_development .row {
    margin: 0 0 30px;
}

.business_development .row h4{
    font-family: Segoe UI;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #323D4F;
}

.business_development .row p{
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: #323D4F;
}

.business_development .row h5{
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
    margin: 12px 0 0;
    color: #323D4F;
}


@media screen and (max-width: 430px) {
    .system_monitoring img {
        width: 100%;
    }
}