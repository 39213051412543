.footer {
    background: #F3F3F3;
    padding: 49px 105px 150px;
}

.footer .footer_items {
    display: flex;
    gap: 200px;
}

.footer .footer_items .footer_item img.logo {
    width: 254px;
    height: 292px;
}

.footer .footer_items .footer_item .social {
    display: flex;
    gap: 9px;
    cursor: pointer;
    justify-content: center;
}

.footer .footer_items .footer_item h3{
    margin: 40px 0 25px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
}

.footer .footer_items .footer_item ul{
    list-style: none;
}

.footer .footer_items .footer_item ul li{
    color: #323D5099;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    margin: 0 0 25px;
    display: flex;
    align-items: center;
    gap: 3px;
}