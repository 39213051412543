@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: Montserrat;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul, li {
  padding: 0;
  margin: 0;
}
