.item {
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
}

.item_x {
  display: flex;
  justify-content: unset;
  gap: 5px;
  align-items: center;
}

.item a {
  color: #323d50;
  text-decoration: none;
}

.item a.active {
  color: #ec1f25;
}

.item img {
  margin-left: 5px;
}

.all_items {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  display: none; /* Hidden by default */
  justify-content: center;
  align-items: center;
}

.item.open .all_items {
  display: flex; /* Display when open */
}

.items {
  box-shadow: 0px 4px 11px 0px #0000004f;
  padding: 50px 44px;
  text-transform: uppercase;
  font-weight: 500;
  min-width: 350px;
  z-index: 1;
  background-color: #fff;
  display: -ms-grid; /* IE11 Grid fallback */
  display: grid; /* Display grid layout */
  gap: 45px;
  margin-top: 40px;
}

.items2 {
  grid-template-columns: 1fr 1fr;
  min-width: 760px;
}

.non-navlink {
  cursor: pointer;
  color: inherit; /* Ensure it has the same styling as a link */
}

.non-navlink.active {
  color: #ec1f25; /* Active state color */
}

@media screen and (max-width: 430px) {
  .item {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    /* width: 100%; */
  }

  .item_x {
    /* display: block; */
    padding: 5px;
    width: 100%;
  }

  .item_x:hover {
    background: #f9f9f9;
  }

  .item img {
    /* margin-left: 8px; */
    width: 12px;
    /* height: auto;
    vertical-align: middle; */
  }

  .all_items {
    position: relative;
    width: 100%;
    display: none;
    flex-direction: column;
  }

  .item.open .all_items {
    display: flex;
  }

  .items {
    position: static;
    box-shadow: none;
    padding: 0;
    text-transform: none;
    font-weight: normal;
    min-width: 100%;
    background-color: #f9f9f9;
    margin-top: 5px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .items2 {
    grid-template-columns: 1fr;
    min-width: 100%;
  }

  /* .item a{
    width: 100%;
  } */

  .item .items span a {
    display: block;
    padding: 8px;
    text-align: left;
    width: 100%;
  }
}
