.container {
    padding: 24px;
    max-width: 1500px;
    margin: 30px auto;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
    color: #4a4a4a;
}

.row-inputs {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-bottom: 16px;
}

.input-group {
    text-align: center;
}

.input-label {
    display: block;
    font-weight: 600;
    color: #4a4a4a;
    margin-bottom: 4px;
}

.input-field {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 80px;
    text-align: center;
}

.file-upload {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.table-container {
    overflow-x: auto;
    margin-top: 15px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.custom-table th,
.custom-table td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
}

.custom-table th {
    background-color: #f0f0f0;
    color: #4a4a4a;
}

.table-row:hover {
    background-color: #f9f9f9;
}

.checkbox-cell {
    text-align: center;
}

.message-container {
    margin-top: 24px;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.message-title {
    font-size: 18px;
    font-weight: 600;
    color: #4a4a4a;
    margin-bottom: 8px;
}

.message-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.send-button-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.send-button {
    background-color: #007bff;
    color: white;
    font-weight: 600;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
}

.send-button:hover {
    background-color: #0056b3;
    color: #fff;
}

.balance-card {
    padding: 16px;
}